<template>
  <div :class="isStudent || isTeacher ? 'container pt-3' : ''">
    <Breadcrumb class="m-0" v-if="isStudent || isTeacher">
      <template slot="restPage">
        <el-breadcrumb-item v-if="isStudent">
          <router-link
            :to="{
              name: 'MyClasses'
            }"
          >
            {{ $t("TeacherCourses.My Classes") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="isTeacher">
          <router-link
            :to="{
              name: 'TeacherClasses'
            }"
          >
            {{ $t("TeacherCourses.Classes I Created") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-page-header
      v-else
      @back="$router.go(-1)"
      :content="myTitle"
    ></el-page-header>

    <el-menu
      :default-active="activeSection"
      class="course-menu mb-3 mt-3"
      mode="horizontal"
      @select="showSection"
      background-color="#f3f3f3"
      :active-text-color="themeColor"
      :router="true"
    >
      <el-menu-item index="details" :route="{ query: { active: 'details' } }">
        <i class="el-icon-s-home"></i>
        {{ $t("TeacherCourses.Info") }}
      </el-menu-item>
      <el-menu-item index="roster" :route="{ query: { active: 'roster' } }">
        <i class="el-icon-user-solid"></i>
        Roster
      </el-menu-item>
      <el-menu-item index="tests" :route="{ query: { active: 'tests' } }">
        <i class="el-icon-s-data"></i>
        Tests
      </el-menu-item>
      <el-menu-item
        index="practices"
        :route="{ query: { active: 'practices' } }"
      >
        <i class="el-icon-s-data"></i>
        Modules & Drills
      </el-menu-item>
      <el-menu-item index="lessons" v-if="false">
        <i class="el-icon-date"></i>
        Lessons
      </el-menu-item>
    </el-menu>
    <div v-show="activeSection === 'details'">
      <Heading heading="h4" :content="$t('TeacherCourses.Info')">
        <template slot="button" v-if="isTeacher || isAdmin || isCompany">
          <router-link
            class="ml-2"
            :to="{
              name: editCourseLink,
              params: {
                id: courseId
              }
            }"
          >
            <el-button type="success" size="mini">
              <i class="fas fa-edit" />
              {{ $t("TeacherCourses.Edit") }}
            </el-button>
          </router-link>
        </template>
      </Heading>
      <hr />
      <el-form label-width="140px">
        <el-form-item
          v-if="course && course.teacher_users"
          :label="$t('TeacherCourses.Teacher')"
          prop="title"
        >
          <b
            class="mr-1"
            v-for="(teacher, index) in course.teacher_users"
            :key="teacher.id"
          >
            <router-link
              v-if="isAdmin"
              :to="{ name: 'AdminViewUser', params: { id: teacher.id } }"
            >
              {{ user.displayName(teacher.first_name, teacher.last_name) }}
            </router-link>
            <template v-else>
              <span class="text-success">
                {{ user.displayName(teacher.first_name, teacher.last_name) }}
              </span>
            </template>
            <span v-if="index < course.teacher_users.length - 1">
              ,
            </span>
          </b>
        </el-form-item>
        <el-form-item :label="$t('TeacherCourses.Class Name')" prop="title">
          <b class="text-success">
            {{ course.name }}
          </b>
        </el-form-item>
        <el-form-item
          :label="$t('TeacherCourses.Class Description')"
          prop="title"
        >
          <span class="text-success">
            <b v-if="course.description">{{ course.description }}</b>
            <b v-else>-</b>
          </span>
        </el-form-item>
        <el-form-item :label="$t('TeacherCourses.Method')" prop="type">
          <b class="text-success" v-if="course.is_online">
            <i class="fa fa-globe"></i>
            {{ $t("TeacherCourses.Online") }}
          </b>
          <b class="text-success" v-else>
            <i aria-hidden="true" class="fa fa-building"></i>
            {{ $t("TeacherCourses.In-Person") }}
          </b>
        </el-form-item>
        <el-form-item :label="$t('TeacherCourses.Class Dates')" prop="date">
          <b class="text-success">
            <template v-if="course.started_on">
              <span v-if="course.started_on">{{
                instant.formatGoogleTime(course.started_on)
              }}</span>
              <span v-if="course.ended_on">
                ~
                {{ instant.formatGoogleTime(course.ended_on) }}
              </span>
            </template>
            <template v-else>
              -
            </template>
          </b>
        </el-form-item>
        <el-form-item :label="$t('TeacherCourses.Class Time')" prop="time">
          <b class="text-success">
            <template v-if="course.time_from">
              <span>{{ course.time_from }}</span>
              <span v-if="course.time_to">
                ~
                {{ course.time_to }}
              </span>
            </template>
            <template v-else>
              -
            </template>
          </b>
        </el-form-item>
        <el-form-item
          :label="$t('TeacherCourses.Number of Students')"
          prop="student_users_count"
        >
          <b class="text-success">
            {{ course.student_users.length }}
          </b>
        </el-form-item>
        <el-form-item
          :label="$t('TeacherCourses.Invite Students')"
          prop="student_users_count"
          v-if="!isStudent"
        >
          <b class="text-success mr-2">
            {{ course.invitation_code }}
          </b>
          <el-tooltip
            class="mr-2"
            effect="dark"
            :content="$t('TeacherCourses.Invitation Link')"
            placement="top"
          >
            <el-button
              type="success"
              size="mini"
              @click="setLink(course.invitation_code)"
            >
              <i class="fas fa-user-plus"></i>
            </el-button>
          </el-tooltip>
        </el-form-item>
      </el-form>
    </div>
    <div v-show="activeSection === 'roster'">
      <Heading heading="h4" content="Roster">
        <template slot="button" v-if="isTeacher || isAdmin || isCompany">
          <router-link
            class="ml-2"
            :to="{
              name: editCourseLink,
              params: {
                id: courseId
              }
            }"
          >
            <el-button type="success" size="mini">
              <i class="fas fa-edit" />
              {{ $t("TeacherCourses.Edit") }}
            </el-button>
          </router-link>
        </template>
      </Heading>
      <hr />
      <div class="ml-3">
        <Heading heading="h6" content="Teacher(s)"> </Heading>
        <el-table
          v-if="course && course.teacher_users"
          border
          :data="course.teacher_users"
          class="mb-3 mt-2"
        >
          <el-table-column :label="$t('TeacherCourses.Teacher')" prop="">
            <template slot-scope="scope">
              <router-link
                v-if="isAdmin"
                :to="{ name: 'AdminViewUser', params: { id: scope.row.id } }"
              >
                <b>
                  {{
                    user.displayName(scope.row.first_name, scope.row.last_name)
                  }}
                </b>
              </router-link>
              <b v-else>
                {{
                  user.displayName(scope.row.first_name, scope.row.last_name)
                }}
              </b>
            </template>
          </el-table-column>
          <el-table-column :label="$t('login.email')" prop="email">
          </el-table-column>
          <el-table-column :label="$t('TeacherCourses.Register Date')">
            <template slot-scope="scope">
              {{ instant.formatGoogleTime(scope.row.created_at) }}
            </template>
          </el-table-column>
        </el-table>
        <Heading heading="h6" content="Student(s)"></Heading>
        <template v-if="isStudent">
          <el-table border :data="getMe(course.student_users)" class="mt-2">
            <el-table-column :label="$t('TeacherCourses.Students')" prop="">
              <template slot-scope="scope">
                <router-link :to="{ name: 'UserProfile' }">
                  <b>
                    {{
                      user.displayName(
                        scope.row.first_name,
                        scope.row.last_name
                      )
                    }}
                  </b>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column :label="$t('login.email')" prop="email">
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Join Date')">
              <template slot-scope="scope">
                {{ instant.formatGoogleTime(scope.row.updated_at) }}
              </template>
            </el-table-column>
          </el-table>
          <p class="text-info">
            {{
              course.student_users.length - 1 > 1
                ? `${course.student_users.length - 1} more students`
                : `${course.student_users.length - 1} more student`
            }}
          </p>
        </template>
        <template v-else>
          <el-table border :data="course.student_users" class="mb-3 mt-2">
            <el-table-column :label="$t('TeacherCourses.Students')" prop="">
              <template slot-scope="scope">
                <router-link
                  :to="{
                    name: isAdmin ? 'AdminViewUser' : 'TeacherViewUser',
                    params: { id: scope.row.id }
                  }"
                >
                  <b>
                    {{
                      user.displayName(
                        scope.row.first_name,
                        scope.row.last_name
                      )
                    }}
                  </b>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column :label="$t('login.email')" prop="email">
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Join Date')">
              <template slot-scope="scope">
                {{ instant.formatGoogleTime(scope.row.updated_at) }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>
    <div
      v-show="activeSection === 'tests' || activeSection === 'practices'"
      class="table-box"
    >
      <Heading
        heading="h4"
        :content="
          practiceType === 'composePractices' ? 'Tests' : 'Modules & Drills'
        "
      >
        <template slot="button" v-if="isTeacher || isAdmin || isCompany">
          <router-link
            class="ml-2"
            :to="{
              name: editCourseLink,
              params: {
                id: courseId
              }
            }"
          >
            <el-button type="success" size="mini">
              <i class="fas fa-edit" />
              {{ $t("TeacherCourses.Edit") }}
            </el-button>
          </router-link>
        </template>
      </Heading>
      <hr />
      <div style="overflow: auto;">
        <table class="table table-bordered" v-if="isStudent">
          <thead>
            <tr>
              <th></th>
              <th
                class="text-center"
                v-for="(student, index) in displayStudents"
                :key="index"
              >
                <router-link>
                  {{ user.displayName(student.first_name, student.last_name) }}
                </router-link>
              </th>
              <th>{{ $t("TeacherCourses.Score Range") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(test, index) in course.session_class_exams"
              :key="index"
              v-show="showTest(test.exam)"
            >
              <td>
                <!-- <el-tooltip
                  effect="dark"
                  :content="test.exam.title"
                  placement="top"
                >
                  <b>TEST {{ index + 1 }}</b>
                </el-tooltip> -->
                <el-tag
                  v-if="
                    getPracticeType(test.exam.test_type) ===
                      'adaptivePractices'
                  "
                  class="mr-2"
                  type="warning"
                >
                  <b> Adaptive</b>
                </el-tag>
                <b class="text-success">{{ test.exam.title }}</b>
              </td>
              <td
                v-for="(student, studentIndex) in displayStudents"
                :key="studentIndex"
              >
                <div style="min-width:300px">
                  <div
                    v-if="
                      student.student_exams &&
                        student.student_exams['Test' + index]
                    "
                    class="text-center"
                  >
                    <template
                      v-if="student.student_exams['Test' + index].user_exam"
                    >
                      <template>
                        <template
                          v-if="getProfile && getProfile.id == student.id"
                        >
                          <template
                            v-if="
                              student.student_exams['Test' + index].user_exam
                                .is_finished == 1
                            "
                          >
                            <OnlineSatScore
                              v-if="
                                student.student_exams['Test' + index].user_exam
                                  .result && practiceType === 'composePractices'
                              "
                              :escore="
                                student.student_exams['Test' + index].user_exam
                                  .result.english_score
                              "
                              :mscore="
                                student.student_exams['Test' + index].user_exam
                                  .result.math_score
                              "
                              :tscore="
                                student.student_exams['Test' + index].user_exam
                                  .total_score
                              "
                              :practiceType="practiceType"
                              :userExamId="
                                student.student_exams['Test' + index].user_exam
                                  .id
                              "
                            ></OnlineSatScore>
                            <OnlineSatScore
                              v-else
                              :tscore="
                                student.student_exams['Test' + index].user_exam
                                  .total_score
                              "
                              :userExamId="
                                student.student_exams['Test' + index].user_exam
                                  .id
                              "
                              :practiceType="practiceType"
                            ></OnlineSatScore>
                          </template>
                          <div
                            v-if="
                              student.student_exams['Test' + index].user_exam
                                .is_finished == 0
                            "
                            class="text-center"
                          >
                            <template
                              v-if="
                                student.student_exams['Test' + index].user_exam
                                  .other_status.start
                              "
                            >
                              <template v-if="inTime(test.exam_date)">
                                <router-link
                                  :to="{
                                    name: isPhone ? 'SATMobileTest' : 'SATTest',
                                    params: {
                                      practiceType: getPracticeType(
                                        test.exam.test_type
                                      ),
                                      id: test.exam.test_id
                                    },
                                    query: {
                                      user_exam_id:
                                        student.student_exams['Test' + index]
                                          .user_exam.id
                                    }
                                  }"
                                >
                                  <el-button
                                    type="warning"
                                    class="add-button"
                                    round
                                  >
                                    <i class="fa fa-play"></i>
                                    Resume
                                  </el-button>
                                </router-link>
                              </template>
                              <template v-else>
                                <el-tooltip
                                  class="item"
                                  effect="dark"
                                  :content="
                                    ` Starts on ${moment(test.exam_date).format(
                                      'YYYY-MM-DD HH:mm'
                                    )}`
                                  "
                                  placement="top"
                                >
                                  <el-button
                                    type="info"
                                    class="add-button"
                                    round
                                    disabled
                                  >
                                    <i class="fa fa-play"></i>
                                    Start
                                  </el-button>
                                </el-tooltip>
                              </template>
                            </template>
                            <template v-else>
                              <router-link
                                :to="{
                                  name: isPhone ? 'SATMobileTest' : 'SATTest',
                                  params: {
                                    practiceType: getPracticeType(
                                      test.exam.test_type
                                    ),
                                    id: test.exam.test_id
                                  },
                                  query: {
                                    user_exam_id:
                                      student.student_exams['Test' + index]
                                        .user_exam.id
                                  }
                                }"
                              >
                                <el-button
                                  type="warning"
                                  class="add-button"
                                  round
                                >
                                  <i class="fa fa-play"></i>
                                  Resume
                                </el-button>
                              </router-link>
                            </template>
                          </div>
                        </template>
                        <template v-else>
                          <i class="fas fa-eye-slash"></i>
                        </template>
                      </template>
                    </template>
                  </div>
                  <template v-else>
                    <div class="text-center">
                      <template
                        v-if="
                          test.exam_date &&
                            moment(test.exam_date).format('YYYY-MM-DD HH:mm') >
                              moment().format('YYYY-MM-DD HH:mm')
                        "
                      >
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="
                            ` Starts on ${moment(test.exam_date).format(
                              'YYYY-MM-DD HH:mm'
                            )}`
                          "
                          placement="top"
                        >
                          <el-button
                            type="info"
                            class="add-button"
                            round
                            disabled
                          >
                            <i class="fa fa-play"></i>
                            Start
                          </el-button>
                        </el-tooltip>
                      </template>
                      <router-link
                        v-else
                        :to="{
                          name: 'SATTestClassPage',
                          params: {
                            classExamId: test.id
                          }
                        }"
                      >
                        <el-button type="success" class="add-button" round>
                          <i class="fa fa-play"></i>
                          Start
                        </el-button>
                      </router-link>
                    </div>
                  </template>
                </div>
              </td>
              <td>
                <div
                  v-if="
                    weeklyScores['Test' + index] &&
                      weeklyScores['Test' + index].max_sat_score > 0
                  "
                >
                  <b>
                    {{
                      `${weeklyScores["Test" + index].min_sat_score} ~ ${
                        weeklyScores["Test" + index].max_sat_score
                      }`
                    }}
                  </b>
                </div>
              </td>
            </tr>
            <tr v-if="practiceType === 'composePractices'">
              <td>
                <el-tooltip
                  effect="dark"
                  content="Improvement for each subject since the first test"
                  placement="top"
                >
                  <b>
                    <i class="far fa-question-circle"></i>
                    &nbsp;Super Score
                  </b>
                </el-tooltip>
              </td>
              <td v-for="(student, index) in displayStudents" :key="index">
                <OnlineSatScore
                  v-if="student.max_score"
                  :escore="student.max_escore"
                  :mscore="student.max_mscore"
                  :tscore="student.max_score"
                  :practiceType="practiceType"
                  :userExamId="null"
                ></OnlineSatScore>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <table class="table table-bordered" v-else>
          <thead>
            <tr>
              <!-- <th
                v-if="isTeacher || isAdmin"
                rowspan="2"
                class="align-middle"
                style="width:100px"
              >
                <label style="margin-bottom:0"
                  ><input
                    type="checkbox"
                    name="check_students"
                    v-model="course.checkedAll"
                    @click="checkedAll(course)"
                  />&nbsp; <b>All</b></label
                >
              </th> -->
              <th rowspan="2" class="align-middle" style="min-width:200px">
                {{ $t("TeacherCourses.Students") }}
              </th>
              <th
                class="text-center align-middle"
                v-for="(test, index) in course.session_class_exams"
                :key="index"
                style="min-width:200px"
                v-show="showTest(test.exam)"
              >
                <!-- <el-tooltip
                  effect="dark"
                  :content="test.exam.title"
                  placement="top"
                >
                  <span>TEST {{ index + 1 }}</span>
                </el-tooltip> -->
                <div class="text-success">
                  <el-tag
                    v-if="
                      getPracticeType(test.exam.test_type) ===
                        'adaptivePractices'
                    "
                    class="mr-2"
                    type="warning"
                  >
                    Adaptive
                  </el-tag>
                  {{ test.exam.title }}
                </div>
                <p>
                  <small v-if="test.exam_date">
                    <b>
                      Starts on
                      {{ moment(test.exam_date).format("YYYY-MM-DD HH:mm") }}
                    </b>
                  </small>
                  &nbsp;
                </p>
                <template>
                  <el-tooltip
                    v-if="
                      getPracticeType(test.exam.test_type) ===
                        'composePractices'
                    "
                    effect="dark"
                    content="Analysis"
                    placement="top"
                  >
                    <router-link
                      :to="{
                        name: analysisRouter,
                        params: { id: courseId },
                        query: {
                          week: index
                        }
                      }"
                    >
                      <el-button type="success" size="mini">
                        <i class="fas fa-chart-line"></i>
                      </el-button>
                    </router-link>
                  </el-tooltip>
                  <el-tooltip
                    v-if="
                      getPracticeType(test.exam.test_type) ===
                        'adaptivePractices'
                    "
                    effect="dark"
                    content="Analysis"
                    placement="top"
                  >
                    <router-link
                      :to="{
                        name: adaptiveAnalysisRouter,
                        params: { id: test.id },
                        query: {
                          exam_id: test.exam.test_id
                        }
                      }"
                    >
                      <el-button type="success" size="mini">
                        <i class="fas fa-chart-line"></i>
                      </el-button>
                    </router-link>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="Browse" placement="top">
                    <router-link
                      :to="{
                        name: getBrowse(test.exam.test_type),
                        query: { id: test.exam.test_id }
                      }"
                      class="ml-4"
                    >
                      <el-button type="success" size="mini">
                        <i class="fa fa-eye"></i>
                      </el-button>
                    </router-link>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="Test link" placement="top">
                    <el-button
                      class="ml-4"
                      type="success"
                      size="mini"
                      @click="setTestLink(test.id, test.exam.title)"
                    >
                      <i class="fas fa-link"></i>
                    </el-button>
                  </el-tooltip>
                  <!-- <el-tooltip
                    effect="dark"
                    :content="$t('TeacherCourses.Invite to take the test')"
                    placement="top"
                  >
                    <el-button
                      class="ml-3"
                      type="success"
                      size="mini"
                      @click="testToStudents(index)"
                    >
                      <i class="fas fa-plus"></i>
                    </el-button>
                  </el-tooltip> -->
                  <!-- <el-tooltip
                    effect="dark"
                    :content="$t('TeacherCourses.Send the test link')"
                    placement="top"
                  >
                    <el-button
                      class="ml-3"
                      type="success"
                      size="mini"
                      @click="mailToStudents(index)"
                    >
                      <i class="fa fa-envelope"></i>
                    </el-button>
                  </el-tooltip> -->
                </template>
              </th>
              <th
                class="text-center align-middle"
                v-if="practiceType === 'composePractices'"
              >
                <el-tooltip
                  effect="dark"
                  content="Improvement for each subject since the first test"
                  placement="top"
                >
                  <span>
                    <i class="far fa-question-circle"></i>
                    &nbsp;Super Score
                  </span>
                </el-tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student, index) in displayStudents" :key="index">
              <!-- <td v-if="isTeacher || isAdmin" style="min-width:100px">
                <label>
                  <input
                    type="checkbox"
                    name="check_students"
                    v-model="student.is_checked"
                    @click="checked(student)"
                  />
                  <b> {{ index + 1 }}</b>
                </label>
              </td> -->
              <td>
                <div>
                  <router-link
                    :to="{
                      name: isAdmin ? 'AdminViewUser' : 'TeacherViewUser',
                      params: { id: student.id }
                    }"
                  >
                    <b>
                      {{
                        user.displayName(student.first_name, student.last_name)
                      }}
                    </b>
                  </router-link>
                </div>
              </td>
              <td
                v-for="(exam, index) in course.session_class_exams"
                :key="index"
                class="text-center"
                style="vertical-align:middle;"
                v-show="showTest(exam.exam)"
              >
                <div style="min-width:300px">
                  <div
                    v-if="
                      student.student_exams &&
                        student.student_exams['Test' + index]
                    "
                    class="text-center"
                  >
                    <template
                      v-if="student.student_exams['Test' + index].user_exam"
                    >
                      <template>
                        <template
                          v-if="
                            student.student_exams['Test' + index].user_exam
                              .is_finished == 1
                          "
                        >
                          <OnlineSatScore
                            v-if="
                              student.student_exams['Test' + index].user_exam
                                .result && practiceType === 'composePractices'
                            "
                            :escore="
                              student.student_exams['Test' + index].user_exam
                                .result.english_score
                            "
                            :mscore="
                              student.student_exams['Test' + index].user_exam
                                .result.math_score
                            "
                            :tscore="
                              student.student_exams['Test' + index].user_exam
                                .total_score
                            "
                            :practiceType="practiceType"
                            :userExamId="
                              student.student_exams['Test' + index].user_exam.id
                            "
                          ></OnlineSatScore>
                          <OnlineSatScore
                            v-else
                            :tscore="
                              student.student_exams['Test' + index].user_exam
                                .total_score
                            "
                            :practiceType="practiceType"
                            :userExamId="
                              student.student_exams['Test' + index].user_exam.id
                            "
                          ></OnlineSatScore>
                        </template>
                        <template
                          v-if="
                            student.student_exams['Test' + index].user_exam
                              .is_finished == 0
                          "
                        >
                          <el-tooltip
                            effect="dark"
                            :content="$t('Courses.In Progress')"
                            placement="top"
                          >
                            <router-link
                              v-if="
                                student.student_exams['Test' + index].user_exam
                                  .id
                              "
                              :to="{
                                name: 'SATResult',
                                params: {
                                  id:
                                    student.student_exams['Test' + index]
                                      .user_exam.id
                                },
                                query: {
                                  practiceType: practiceType
                                }
                              }"
                            >
                              <el-tag
                                style="width: 12rem;"
                                type="success"
                                effect="plain"
                              >
                                <b
                                  v-if="
                                    student.student_exams['Test' + index]
                                      .user_exam.other_status.pastOrder
                                  "
                                >
                                  <i class="fas fa-user-check"></i>
                                  {{
                                    `Module: ${
                                      student.student_exams["Test" + index]
                                        .user_exam.other_status.pastOrder
                                        .sectionOrder
                                    }`
                                  }},
                                  {{
                                    `Question: ${
                                      student.student_exams["Test" + index]
                                        .user_exam.other_status.pastOrder
                                        .questionOrder
                                    }`
                                  }}
                                </b>
                                <b v-else>
                                  <i class="fas fa-user-check"></i>
                                  Module: 1, Question: 1
                                </b>
                              </el-tag>
                            </router-link>
                          </el-tooltip>
                        </template>
                        <!-- <div class="mt-3">
                          <el-tooltip
                            effect="dark"
                            :content="
                              $t('TeacherCourses.Re-invite to take the test')
                            "
                            placement="top"
                          >
                            <el-button
                              class=""
                              type="warning"
                              size="mini"
                              @click="setExamUser(student.id, index)"
                            >
                              <i class="fas fa-plus"></i>
                            </el-button>
                          </el-tooltip>
                          <el-tooltip
                            effect="dark"
                            :content="$t('TeacherCourses.Send the test link')"
                            placement="top"
                          >
                            <el-button
                              class="ml-3"
                              type="success"
                              size="mini"
                              @click="sendExamMail(student.id, index)"
                            >
                              <i class="fa fa-envelope"></i>
                            </el-button>
                          </el-tooltip>
                        </div> -->
                      </template>
                    </template>
                  </div>
                  <template v-else>
                    <div class="text-center">
                      <div>
                        <div v-if="exam.exam_date">
                          <el-tag
                            type="info"
                            style="width: 12rem;"
                            v-if="
                              moment(exam.exam_date).format(
                                'YYYY-MM-DD HH:mm'
                              ) > moment().format('YYYY-MM-DD HH:mm')
                            "
                            effect="dark"
                          >
                            <b>
                              Starts on
                              {{
                                moment(exam.exam_date).format(
                                  "YYYY-MM-DD HH:mm"
                                )
                              }}
                            </b>
                          </el-tag>
                          <div v-else>
                            <el-tag
                              style="width: 12rem;"
                              type="success"
                              effect="plain"
                            >
                              <b>
                                <i class="fas fa-user"></i>
                                Pending
                              </b>
                            </el-tag>
                          </div>
                        </div>
                        <div v-else>
                          <el-tag
                            style="width: 12rem;"
                            type="success"
                            effect="plain"
                          >
                            <b>
                              <i class="fas fa-user"></i>
                              Pending
                            </b>
                          </el-tag>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </td>
              <td
                style="min-width:300px"
                v-if="practiceType === 'composePractices'"
              >
                <OnlineSatScore
                  v-if="student.max_score"
                  :escore="student.max_escore"
                  :mscore="student.max_mscore"
                  :tscore="student.max_score"
                  :practiceType="practiceType"
                  :userExamId="null"
                ></OnlineSatScore>
              </td>
            </tr>
            <tr v-if="practiceType === 'composePractices'">
              <td colspan="1" class="text-center">
                <b>AVG</b>
              </td>
              <td
                v-for="(exam, index) in course.session_class_exams"
                :key="index"
                v-show="showTest(exam.exam)"
              >
                <OnlineSatScore
                  v-if="exam.count"
                  :escore="Math.round(exam.e_total / exam.count)"
                  :mscore="Math.round(exam.m_total / exam.count)"
                  :tscore="
                    Math.round((exam.e_total + exam.m_total) / exam.count)
                  "
                  :practiceType="practiceType"
                  :userExamId="null"
                ></OnlineSatScore>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="1" class="text-center">
                <b>{{ $t("TeacherCourses.Score Range") }}</b>
              </td>
              <td
                v-for="(exam, index) in course.session_class_exams"
                :key="index"
                class="text-center"
                v-show="showTest(exam.exam)"
              >
                <template v-if="weeklyScores">
                  <div
                    v-if="
                      weeklyScores['Test' + index] &&
                        weeklyScores['Test' + index].max_sat_score > 0
                    "
                  >
                    <b>
                      {{
                        `${weeklyScores["Test" + index].min_sat_score} ~ ${
                          weeklyScores["Test" + index].max_sat_score
                        }`
                      }}
                    </b>
                  </div>
                </template>
              </td>
              <td v-if="practiceType === 'composePractices'"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-show="activeSection === 'lessons'">
      <Heading heading="h4" :content="$t('TeacherCourses.Lessons')">
        <template slot="button" v-if="isTeacher || isAdmin || isCompany">
          <router-link
            class="ml-2"
            :to="{
              name: editCourseLink,
              params: {
                id: courseId
              }
            }"
          >
            <el-button type="success" size="mini">
              <i class="fas fa-edit" />
              {{ $t("TeacherCourses.Edit") }}
            </el-button>
          </router-link>
        </template>
      </Heading>
      <hr />
      <Lessons :subjects="subjects"></Lessons>
    </div>
    <el-dialog
      class="class-link"
      :title="
        linkType === 'Class'
          ? $t('TeacherCourses.Class Invitation Link')
          : linkTypeTitle
      "
      :visible.sync="showLink"
      width="50%"
    >
      <p>{{ $t("TeacherCourses.Copy and send this link to students") }}</p>
      <div class="text-center">
        <div class="referral-code">
          <a>
            <span>
              {{ link }}
            </span>
          </a>
          <el-button class="ml-3" type="success" @click="copyLink">
            <i class="fas fa-copy"></i>
            {{ $t("toefl.Copy Link") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import role from "@/mixins/role.js";
import OnlineSatScore from "@/views/courses/components/SATScore";
import { instant, user } from "@ivy-way/material";
import Common from "@/mixins/common.js";
import Class from "@/apis/class.js";
import baseDomainPath from "@/common/baseDomainPath";
import Lessons from "@/views/videoClasses/Detail.vue";
import moment from "moment";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb, OnlineSatScore, Lessons },

  mixins: [Common, role],

  props: [],
  data() {
    return {
      moment,
      linkType: "Class",
      linkTypeTitle: null,
      newTest: {
        isNew: 1,
        subject: null,
        title: ""
      },
      subjects: [],
      activeSection: "details",
      course: null,
      displayStudents: null,
      weeklyScores: null,
      showForStudent: true,
      showLink: false
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),

    analysisRouter() {
      let router = "TeacherAnalysis";
      if (this.isAdmin) {
        router = "AdminAnalysis";
      }
      return router;
    },
    adaptiveAnalysisRouter() {
      let router = "TeacherAdaptiveAnalysis";
      if (this.isAdmin) {
        router = "AdminAdaptiveAnalysis";
      }
      return router;
    },
    editCourseLink() {
      if (this.isAdmin) {
        return "AdminEditCourse";
      } else if (this.isTeacher) {
        return "TeacherEditCourse";
      } else if (this.isCompany) {
        return "CompanyEditCourse";
      } else {
        return "AdminEditCourse";
      }
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    isStudent() {
      return this.$route.name === "StudentCourseDetail";
    },
    isTeacher() {
      return this.$route.name === "TeacherCourseDetail";
    },
    isAdmin() {
      return this.$route.name === "AdminCourseDetail";
    },
    isCompany() {
      return this.$route.name === "CompanyCourseDetail";
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    myTitle() {
      return this.course ? this.course.name : "-";
    },
    courseId() {
      return this.$route.params.id;
    },
    practiceType() {
      if (this.activeSection === "tests") {
        return "composePractices";
      } else {
        return "singlePractice";
      }
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.params.id) {
      this.reset();
    }
    if (this.$route.query.active) {
      this.activeSection = this.$route.query.active;
    }
    this.subjects = [
      {
        id: 26,
        name: "333",
        materials: [
          {
            id: 77,
            material_subject_id: 26,
            title: "333",
            description: "33333",
            publish_at: "2023-09-06",
            material_files: []
          }
        ]
      },
      {
        id: 27,
        name: "444",
        materials: [
          {
            id: 78,
            material_subject_id: 27,
            title: "23123",
            description: "231232312323123",
            publish_at: "2023-09-06",
            material_files: [
              {
                id: 266,
                material_id: 78,
                type: "link",
                name: "https://www.baidu.com/",
                link: "https://www.baidu.com/",
                extension: null
              }
            ]
          },
          {
            id: 79,
            material_subject_id: 27,
            title: "333333333",
            description: "333333333",
            publish_at: "2023-09-06",
            material_files: [
              {
                id: 267,
                material_id: 79,
                type: "link",
                name: "https://translate.google.com.hk/",
                link: "https://translate.google.com.hk/",
                extension: null
              }
            ]
          }
        ]
      }
    ];
  },

  methods: {
    getPracticeType(type) {
      if (type === "Modules\\Practice\\Entities\\ComposePractice") {
        return "composePractices";
      } else if (type === "App\\Entities\\AdaptivePractice") {
        return "adaptivePractices";
      } else if (type === "Modules\\Practice\\Entities\\Practice") {
        return "singlePractice";
      }
    },
    getBrowse(type) {
      console.log(type);
      if (type === "Modules\\Practice\\Entities\\ComposePractice") {
        return "SatComposePracticeDetailAll";
      } else if (type === "Modules\\Practice\\Entities\\Practice") {
        return "SatPracticeDetailAll";
      } else if (type === "App\\Entities\\AdaptivePractice") {
        return "SatAdaptiveDetailAll";
      }
    },
    getMe(arr) {
      return arr.filter(item => item.id === this.getProfile.id);
    },
    showTest(test) {
      if (this.activeSection === "tests") {
        if (!test.test.marks[1]) {
          return true;
        } else {
          return false;
        }
      } else {
        if (test.test.marks[1]) {
          return true;
        } else {
          return false;
        }
      }
    },
    showPractice(test) {
      console.log(test);
      if (test.test.marks[1]) {
        return true;
      } else {
        return false;
      }
    },
    inTime(time) {
      console.log(moment(time).format("YYYY-MM-DD HH:mm"));
      console.log(moment().format("YYYY-MM-DD HH:mm"));
      return (
        moment(time).format("YYYY-MM-DD HH:mm") <=
        moment().format("YYYY-MM-DD HH:mm")
      );
    },
    async reset() {
      await this.getClass(this.$route.params.id);
      await this.getClassExamOverView(this.$route.params.id);
      await this.setClasses();
      await this.getAvg();
    },
    setClasses() {
      this.course["checkedAll"] = true;
      (this.displayStudents || []).forEach(student => {
        student["is_checked"] = true;

        let old_escore = 0;
        let old_mscore = 0;
        let old_score = 0;

        let first_escore = 0;
        let first_mscore = 0;
        let first_score = 0;

        let max_escore = 0;
        let max_mscore = 0;
        let max_score = 0;

        let count = 0;
        this.course.session_class_exams.forEach((exam, index) => {
          exam["count"] = 0;

          exam["e_total"] = 0;
          exam["m_total"] = 0;
          exam["t_total"] = 0;

          exam["e_up"] = 0;
          exam["m_up"] = 0;
          exam["t_up"] = 0;

          if (
            student.student_exams !== undefined &&
            student.student_exams["Test" + index] !== undefined
          ) {
            if (
              student.student_exams["Test" + index].user_exam &&
              student.student_exams["Test" + index].user_exam.result
            ) {
              count++;
              // student.student_exams["Test" + index].user_exam.result
              if (count === 1) {
                student.student_exams["Test" + index].user_exam.result[
                  "escore_up"
                ] = 0;
                student.student_exams["Test" + index].user_exam.result[
                  "mscore_up"
                ] = 0;
                student.student_exams["Test" + index].user_exam.result[
                  "score_up"
                ] = 0;
                first_escore =
                  student.student_exams["Test" + index].user_exam.result
                    .english_score;
                first_mscore =
                  student.student_exams["Test" + index].user_exam.result
                    .math_score;
                first_score =
                  student.student_exams["Test" + index].user_exam.total_score;
              } else {
                student.student_exams["Test" + index].user_exam.result[
                  "escore_up"
                ] =
                  student.student_exams["Test" + index].user_exam.result
                    .english_score - old_escore;
                student.student_exams["Test" + index].user_exam.result[
                  "mscore_up"
                ] =
                  student.student_exams["Test" + index].user_exam.result
                    .math_score - old_mscore;
                student.student_exams["Test" + index].user_exam.result[
                  "score_up"
                ] =
                  student.student_exams["Test" + index].user_exam.total_score -
                  old_score;
              }
              //Save Old Score
              old_escore =
                student.student_exams["Test" + index].user_exam.result
                  .english_score;
              old_mscore =
                student.student_exams["Test" + index].user_exam.result
                  .math_score;
              old_score =
                student.student_exams["Test" + index].user_exam.total_score;
              // Super score
              if (
                student.student_exams["Test" + index].user_exam.result
                  .english_score > max_escore
              ) {
                max_escore =
                  student.student_exams["Test" + index].user_exam.result
                    .english_score;
              }

              if (
                student.student_exams["Test" + index].user_exam.result
                  .math_score > max_mscore
              ) {
                max_mscore =
                  student.student_exams["Test" + index].user_exam.result
                    .math_score;
              }
            }
          }
        });
        max_score = max_escore + max_mscore;

        let max_escore_up = max_escore - first_escore;
        let max_mscore_up = max_mscore - first_mscore;
        let max_score_up = max_score - first_score;
        student["max_escore"] = max_escore;
        student["max_mscore"] = max_mscore;
        student["max_score"] = max_score;

        student["max_escore_up"] = max_escore_up;
        student["max_mscore_up"] = max_mscore_up;
        student["max_score_up"] = max_score_up;
      });
      this.course = JSON.parse(JSON.stringify(this.course));
      // this.getAvg(sectionClass);
      // return sectionClass;
    },
    checkedAll(course) {
      course.checkedAll = !course.checkedAll;
      this.displayStudents.forEach(student => {
        student.is_checked = course.checkedAll;
      });
      this.getAvg();
    },
    checked(student) {
      student.is_checked = !student.is_checked;
      let count = 0;
      this.displayStudents.forEach(student => {
        if (student["is_checked"] == true) {
          count++;
        }
      });
      if (this.displayStudents.length === count) {
        this.course.checkedAll = true;
      } else {
        this.course.checkedAll = false;
      }
      this.getAvg();
    },
    getAvg() {
      let e_old = 0;
      let m_old = 0;
      let t_old = 0;
      (this.course.session_class_exams || []).forEach((exam, index) => {
        exam.e_total = 0;
        exam.m_total = 0;
        exam.t_total = 0;
        exam.e_up = 0;
        exam.m_up = 0;
        exam.t_up = 0;
        exam["count"] = 0;
        // exam["onlineCount"] = 0;
        (this.displayStudents || []).forEach(student => {
          if (student.is_checked) {
            if (
              student.student_exams &&
              student.student_exams["Test" + index] &&
              student.student_exams["Test" + index].user_exam &&
              student.student_exams["Test" + index].user_exam.result
            ) {
              exam["count"]++;
              exam.e_total +=
                student.student_exams[
                  "Test" + index
                ].user_exam.result.english_score;
              exam.m_total +=
                student.student_exams[
                  "Test" + index
                ].user_exam.result.math_score;

              exam.t_total +=
                student.student_exams["Test" + index].user_exam.total_score;
            }
          }
        });

        if (t_old > 0) {
          if (exam["count"] > 0) {
            exam.e_up = this.showScore(exam.e_total, exam["count"]) - e_old;
            exam.m_up = this.showScore(exam.m_total, exam["count"]) - m_old;
            exam.t_up = exam.r_up + exam.g_up + exam.m_up;
          }
        }
        e_old = this.showScore(exam.e_total, exam["count"]);
        m_old = this.showScore(exam.m_total, exam["count"]);
        t_old = e_old + m_old;
      });
      this.displayStudents = JSON.parse(JSON.stringify(this.displayStudents));
    },
    showScore(total, count) {
      let score = "";
      if (count > 0) {
        score = Math.round(total / count);
      }
      return score;
    },
    setLink(code) {
      this.linkType = "Class";
      this.link = `${baseDomainPath}join?code=${code}`;
      this.showLink = true;
    },
    setTestLink(classExamId, title) {
      this.linkType = "Test";
      this.linkTypeTitle = title;
      this.link = `${baseDomainPath}class/exam/${classExamId}`;
      this.showLink = true;
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    },
    async getClassExamOverView(id) {
      if (this.isStudent) {
        const res = await Class.getMyClassExamOverView({
          session_class_id: id
        });
        this.displayStudents = res.session_class_students;
        this.weeklyScores = res.weekly_min_max_scores;
      } else {
        const res = await Class.getClassExamOverView({
          session_class_id: id
        });
        this.displayStudents = res.session_class_students;
        this.weeklyScores = res.weekly_min_max_scores;
      }
    },
    async getClass(id) {
      const res = await Class.getClassDetail(id);
      this.course = res.session_class;
    },
    async testToStudents(week) {
      let student_user_ids = [];

      if (this.displayStudents && this.displayStudents.length > 0) {
        this.displayStudents.forEach(student => {
          if (student.is_checked) {
            student_user_ids.push(student.id);
          }
        });
      }
      if (student_user_ids && student_user_ids.length > 0) {
        this.$confirm(
          this.$t("Courses.Allow selected student(s) access to the test?"),
          "",
          {
            confirmButtonText: this.$t("alert.confirm"),
            cancelButtonText: this.$t("alert.cancel"),
            type: "warning"
          }
        )
          .then(async () => {
            await Class.testToStudents(this.courseId, {
              which_week: week,
              student_user_ids: student_user_ids
            });
            this.reset();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.$t("alert.Canceled")
            });
          });
      } else {
        this.$message({
          message: this.$t("Courses.Please choose at least 1 student"),
          type: "warning"
        });
      }
    },
    async mailToStudents(week) {
      let student_user_ids = [];

      if (this.displayStudents && this.displayStudents.length > 0) {
        this.displayStudents.forEach(student => {
          if (student.is_checked) {
            student_user_ids.push(student.id);
          }
        });
      }
      if (student_user_ids && student_user_ids.length > 0) {
        this.$confirm(
          this.$t("Courses.Send test link to these students?"),
          "",
          {
            confirmButtonText: this.$t("alert.confirm"),
            cancelButtonText: this.$t("alert.cancel"),
            type: "warning"
          }
        )
          .then(async () => {
            let student_user_ids = [];

            this.displayStudents.forEach(student => {
              if (student.is_checked) {
                student_user_ids.push(student.id);
              }
            });

            await Class.mailToStudents(this.courseId, {
              which_week: week,
              student_user_ids: student_user_ids
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.$t("alert.Canceled")
            });
          });
      } else {
        this.$message({
          message: this.$t("Courses.Please choose at least 1 student"),
          type: "warning"
        });
      }
    },
    async setExamUser(user_id, week) {
      let student_user_ids = [user_id];
      await Class.testToStudents(this.courseId, {
        which_week: week,
        student_user_ids: student_user_ids
      });
      this.reset();
    },
    async sendExamMail(user_id, week) {
      let student_user_ids = [user_id];
      await Class.mailToStudents(this.courseId, {
        which_week: week,
        student_user_ids: student_user_ids
      });
    },
    showSection(index) {
      this.activeSection = index;
    }
  }
};
</script>

<style scoped>
.add-button {
  width: 150px;
}
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box .left-menu {
  width: 200px;
  height: 100%;
}
.box .right-content {
  background: #fafafa;
}
.table-box {
  overflow: auto;
}
.right-content-detail {
  width: inherit;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 1rem 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}
.referral-code span {
  display: inline-block;
  height: 41px;
  line-height: 41px;
  padding: 0 1rem;
  font-size: 1rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
::v-deep .class-link .el-dialog__body {
  padding-top: 0.2rem;
}
table td {
  vertical-align: middle;
}
</style>
