var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[(_vm.escore)?_c('el-tooltip',{attrs:{"effect":"dark","content":"English","placement":"top"}},[_c('b',[_vm._v(" "+_vm._s(_vm.escore)+" + ")])]):_vm._e(),(_vm.mscore)?_c('el-tooltip',{attrs:{"effect":"dark","content":"Math","placement":"top"}},[_c('b',[_vm._v(" "+_vm._s(_vm.mscore)+" = ")])]):_vm._e(),_c('el-tooltip',{attrs:{"effect":"dark","content":"Total","placement":"top"}},[_c('b',[(_vm.userExamId > 0)?_c('router-link',{attrs:{"to":{
          name: 'SATResult',
          params: {
            id: _vm.userExamId
          },
          query: {
            practiceType: _vm.practiceType
          }
        }}},[_vm._v(" "+_vm._s(_vm.tscore)+" ")]):_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.tscore)+" ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }